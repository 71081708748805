export const style = {
  api: {
    apiProduction: "https://ioasys.prd.appnoz.com.br/",
    newApiProduction: "null",
    samlUrl: "http://saml-api.appnoz.com.br/auth/login/ioasys",
    nameProfile: "ioasys",
    hostHeader: "ioasys",
  },

  icons: {
    loginIcon: "https://timcoo-geral.s3.amazonaws.com/Logos/ioasys.png",
    secondaryLogonLogo: "null",
    menuIcon:
      localStorage.getItem("menuIcon") ||
      "https://timcoo-geral.s3.amazonaws.com/Logos/secondary_logo_ioasys.png",
    browserLogo:
      "https://timcoo-geral.s3.amazonaws.com/Logos/browser_logo_ioasys.png",
  },

  shareAttributes: {
    shareImage: "https://timcoo-geral.s3.amazonaws.com/Logos/ioasys.png",
    shareTitle: "Noz - Plataforma de comunicação",
    shareDescription:
      "O NOZ é um aplicativo de comunicação personalizado, onde é possível criar conteúdos de acordo com a segmentação do seu público de maneira gamificada.",
  },

  names: {
    nameApp: localStorage.getItem("nameApp") || "Ioasys Team",
    nameScore: "Nozes",
    nameStore: "ioasys Store",
    nameAcademy: localStorage.getItem("nameAcademy") || "Escola ioasys",
    nameTeam: localStorage.getItem("nameTeam") || "Time",
    nameCalendar: localStorage.getItem("nameCalendar") || "Calendário",
    nameTools: localStorage.getItem("nameTools") || "Links Úteis",
  },

  colors: {
    primaryColor: localStorage.getItem("primaryColor") || "#501F99",
    secondaryColor: localStorage.getItem("secondaryColor") || "#B3247C",
    tertiaryColor: "",
  },

  variables: {
    gamificationEnabled: true,
    child_profiles: [],
    hasPrivacyPolicies: false,
    externalUse: false,
    customClient: true,
    customMenuItem: null,
    autoRegister: null,
  },
};

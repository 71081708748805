import { style } from "style";

const storeRedeemMessage = () => {
  switch (style.names.nameApp) {
    case "Pintou Parceria Suvinil":
      return "Resgate realizado com sucesso! Para acompanhar e/ou informar dados entre em contato no whatsapp 11 99764-1892.";
    case "PetroRio":
      return "Pronto, agora é só aguardar! O time de RH enviará os itens para você em breve!";
    case "MRN":
      return "Tudo certo! Entre em contato com Comunicação para resgatar seu produto.";
    case "youseed":
      return "Tudo certo! Entre em contato com o CI para resgatar seu produto.";
    default:
      return "Tudo certo! Entre em contato com o RH para resgatar seu produto.";
  }
};

export const fileMenuName = () => {
  switch (style.names.nameApp) {
    case "Grupo Arizona":
      return "Doc's";
    case "Foton":
      return "Galeria";
    case "The Bakery Brasil":
      return "Files";
    case "mestreconstrutor":
      return "Onde Encontrar";
    case "Fóton":
      return "Galeria";
    default:
      return "Arquivos";
  }
};

export const muralMenuName = () => {
  switch (style.names.nameApp) {
    case "The Bakery Brasil":
      return "Home";
    default:
      return "Mural";
  }
};

export const favoriteMenuName = () => {
  switch (style.names.nameApp) {
    case "The Bakery Brasil":
      return "Favorites";
    default:
      return "Favoritos";
  }
};

export const toolsMenuName = () => {
  switch (style.names.nameApp) {
    case "The Bakery Brasil":
      return "Tools";
    default:
      return style.names.nameTools;
  }
};

export const calendarMenuName = () => {
  switch (style.names.nameApp) {
    case "The Bakery Brasil":
      return "Calendar";
    default:
      return style.names.nameCalendar;
  }
};

export const historicMenuName = () => {
  switch (style.names.nameApp) {
    case "The Bakery Brasil":
      return "Nozes Wallet";
    default:
      return `Histórico de ${style.names.nameScore}`;
  }
};

export const birthdayMenuName = () => {
  switch (style.names.nameApp) {
    case "The Bakery Brasil":
      return "Birthdays";
    default:
      return "Aniversários";
  }
};

export const teamMenuName = () => {
  switch (style.names.nameApp) {
    case "The Bakery Brasil":
      return "Team";
    default:
      return style.names.nameTeam;
  }
};

export const configMenuName = () => {
  switch (style.names.nameApp) {
    case "The Bakery Brasil":
      return "Settings";
    default:
      return "Configurações";
  }
};

export default {
  "pt-BR": {
    menu: {
      feed: muralMenuName(),
      groupPardini: "Grupo Pardini",
      aboutUs: "Sobre Nós",
      favorite_posts: favoriteMenuName(),
      calendar: calendarMenuName(),
      birthdays: birthdayMenuName(),
      academy: style.names.nameAcademy,
      market: style.names.nameStore,
      team: teamMenuName(),
      files: fileMenuName(),
      settings: configMenuName(),
      tools: toolsMenuName(),
      gallery: "Galeria",
      score_history: historicMenuName(),
      notifications: "Minhas Notificações",
      holerite: "Holerite",
      wayCibraOfBeing: "Jeito Cibra de ser",
      socialResponsability: "Responsabilidade social",
      nonExistingPublication: "Publicação não existente",
      services: "Serviços",
      sac: "Atendimento",
    },

    groupPardiniSubmenu: {
      bio: "Sobre Nós",
    },

    academy: {
      textPage: {
        notCursesFound:
          "Aqui você irá conferir curiosidades e informações interativas para ampliar o seu conhecimento. Estamos construindo essa seção e, em breve, teremos novidades!",
        noCoursesAvailableToStartAtThisTime:
          "Nenhum curso disponível para iniciar no momento",
      },
      sidebar: {
        title:
          style.variables.child_profiles?.length > 0
            ? "Realizados"
            : "Meus Cursos",
      },
      couseDetail: {
        modules: "Módulos",
        resetCourse: "Reiniciar Curso",
        finalizedModule: "Módulo Finalizado",
        finalizedCourse: "Curso Finalizado",
      },
    },

    arquives: {
      textPage: {
        download: "Baixar",
        notFilesFound: "Ainda não existem pastas ou arquivos.",
        notGalleryFound: "Ainda não existem fotos ou videos.",
        notFilesFoundAtSearch: "Nenhum arquivo foi encontrado em sua busca",
        toView: "Visualizar",
      },
    },

    birthdays: {
      textPage: {
        clickHere: "Clique Aqui",
        know: "Conheça",
        todayWeHave: "Hoje temos",
        birthday: "aniversariante",
        birthdayPlural: "aniversariantes",
      },
    },

    calendar: {
      textPage: {
        events: "Eventos",
        event: "Evento",
        holidays: "Feriados",
        holiday: "Feriado",
        nextEvent: "Próximos Eventos",
        subtitle: "Legenda",
        birthdaysOfTheDay: "Aniversários do dia",
        noEventsForThatDay: "Nenhum evento para esse dia",
        notNextEvents: "Não há eventos programados",
        noBirthdayForThatDay: "Não há aniversariantes hoje",
      },
    },

    general: {
      email: "E-MAIL",
      password: "SENHA",
      user: "LOGIN",
      buttons: {
        add: "Adicionar",
        back: "Voltar",
        buy: "Comprar",
        cancel: "Cancelar",
        confirm: "Confirmar",
        edit: "Editar",
        editProfile: "Editar Perfil",
        viewProfile: "Ver Perfil",
        myProfile: "Meu Perfil",
        enter: "Entrar",
        exit: "Sair",
        doYouWantToLeave: "Deseja sair?",
        ok: "OK",
        save: "SALVAR",
        send: "Enviar",
        update: "Atualizar",
        viewAll: "Ver todas",
        markAsRead: "Marcar como lidas",
        markAllNotificationsAsRead: "Marcar todas as notificações como lidas",
        goListNotifications: "Ir para listagem das notificações",
        access: "Acessar",
        inputRequired: "Campo obrigatório",
        inputCPFInvalid: "CPF inválido",
        inputCNPJInvalid: "CNPJ inválido",
        inputDateInvalid: "Data inválida",
        inputEmailInvalid: "E-mail inválido",
        inputPhoneInvalid: "Telefone inválido. Ex: (xx) x xxxx-xxxx",
        loginInvalid:
          "O nome de usuário só pode conter letras, números, sublinhados (_), hífens (-) e pontos. Espaços e caracteres especiais não são aceitos.",
        remove: "Remover",
        continue: "Continuar",
        finish: "Finalizar",
        change: "Alterar",
        backToTop: "Voltar ao topo",
        valid: "Validar",
      },
      textPage: {
        optionsOfUser: "Opções do usuário",
        returnToHome: "Voltar para tela inicial",
        goTo: "Ir para",
        goToProfile: "Ir para o perfil de",
        view: "Ver",
        viewHistoryOfMyCoins: "Ver histórico das minhas moedas",
        viewHistory: "Ver histórico",
        acessToFolder: "Acessar a pasta",
        clickToGoToContant: "Clique para ir ao conteúdo",
        clickToShowMore: "Clique para mostrar mais",
        clickOfAcessProfile: "Clique para acessar o perfil",
        searchPublication: "Pesquisar publicação",
        in: "na",
        nameCoins: style.names.nameScore,
        people: "pessoas",
        resultsFor: "Resultados para",
        search: "Pesquisa",
        genres: {
          male: "do",
          feminine: "da",
          ungenerous: "dx",
        },
        typeYourEmail: "Digite seu e-mail",
      },
    },

    login: {
      cibraTeams: "Acesso",
      textPage: {
        dontWorryWeWillSendYouAMessageToRecoverYourPassword:
          "Fique tranquilo! Enviaremos um e-mail com as instruções pra você recuperar a sua senha. Caso não tenha e-mail, entre em contato com o administrador",
        enterTheEmailAddressAssociatedWithYourAccount:
          "Digite o e-mail associado à sua conta",
        forgotPassword: "Esqueci minha senha",
        forgotPasswordExternalRecovery: "Esqueceu sua senha?",
        iWantToRegister: "Quero me cadastrar",
        rememberMe: "Lembre-se de mim.",
        typeYourPassword: "Digite sua senha",
        typeYourEmail: "Digite seu email",
        typeYourUser: "Digite seu usuário",
        createYourUser: "Crie o nome do seu usuário",
        typeYourCity: "Digite sua cidade",
        invalidToken: "Token Inválido",
      },
    },

    modal: {
      textPage: {
        areYouSureWantToQuit: "Você tem certeza que deseja sair?",
        somethingWentWrong: "Ops, algo deu errado!",
        submitTheForm: "Enviar o formulário?",
        submitTheAnswer: "Enviar a resposta?",
        successModal: "Sucesso!",
        attention: "Atenção",
        youAlreadyVotedSurvey: "Você já votou nesta enquete!",
        confirmPurchase: "Confirmar compra",
        confirmApps: "Deseja acessar esse link?",
        formSent: "Sua resposta foi enviada.",
        termsOfUse: "Termos de uso",
        youAgreeToOurTermsOfUse: "Você concorda com nossos termos de uso?",
        validCourse: "Validar Curso",
        presentialCourse: "Curso presencial",
        insertCode: "insira o código de validação",
        courseValidSuccess: "Curso validado com sucesso",
        accountDeletionMessage:
          "Ao confirmar a exclusão, seus dados e a sua pontuação serão apagados do sistema. Esta ação não poderá ser desfeita.",
        deletedAccount: "Conta excluída",
        deletedAccountInfo:
          "Seu acesso será encerrado e os seus dados serão apagados do nosso sistema em até 72 horas. Esperamos que você volte em breve. Até logo!",
        quickSearch: "Pesquisa rápida",
        quickSearchQuestion:
          "Qual emoji melhor representa a última semana de trabalho?",
        betweenTheDays: "Entre os dias",
        to: "à",
        replyAsAnonymous: "Responder como anônimo",
        sendReply: "Enviar Resposta",
        ratherNotAnswer: "Prefiro não responder",
        thanksForParticipating: "Obrigado por ajudar a criar um clima melhor.",
        weWaitBextTime: "Ok, aguardamos sua participação na próxima.",
        youWillBeRedirectedToTheWall: "Você será redirecionado ao mural.",
      },
    },

    myNotifications: {
      textPage: {
        notifications: "Notificações",
        notNotifications: "Não há notificações",
      },
    },

    profile: {
      pontuation: "Pontuação",
      bio: "SOBRE MIM",
      canHelp: "POSSO AJUDAR COM",
      datebirthday: "DATA DE NASCIMENTO",
      email: "E-MAIL",
      facebook: "FACEBOOK",
      instagram: "INSTAGRAM",
      interests: "INTERESSES",
      linkedin: "LINKEDIN",
      name: "NOME COMPLETO",
      nickname: "COMO PREFERE SER CHAMADO?",
      login: "LOGIN",
      phone: "TELEFONE",
      cellPhone: "WHATSAPP",
      sex: "SEXO",
      gender: "GÊNERO",
      tags: "TAGS",
      twitter: "TWITTER",
      username: "USERNAME",
      otherGender: "OUTRO GÊNERO",
      otherSex: "OUTRO SEXO",
      state: "ESTADO",
      city: "CIDADE",
      timeExperience: "TEMPO DE EXPERIÊNCIA",
      phoneRamal: "RAMAL",
      cpf: "CPF",
      cep: "CEP",
      address: "RUA/AV.",
      number: "NÚMERO",
      complement: "COMPLEMENTO",
      neighborhood: "BAIRRO",
      password: "CRIE UMA SENHA",
      readAndAccepted: "Eu li e aceito o",
      termOfUse: "termo de uso",
      termOfUseAndPolicy: "termo de uso e politica de privacidade",
      lgpd: "LGPD",
      activity: "ATIVIDADE",
      appDiscovery: "COMO VOCÊ CONHECEU O APLICATIVO PINTOU PARCERIA?",
      cnpj: "CNPJ",
      profession: "PROFISSÃO",
      partner: "VOCÊ É PARCEIRO?",

      suvinilProfessional: {
        typeWork: "TIPOS DE TRABALHO QUE REALIZA",
        workRegime: "REGIME DE TRABALHO",
        training: "JÁ FEZ ALGUM TREINAMENTO SUVINIL?",
        interests: "INTERESSES",
        whatTrainings: "QUAIS TREINAMENTOS?",
      },

      textPage: {
        addImage: "Adicionar foto",
        female: "Feminino",
        hello: "Olá",
        FrontEndBackEndDesignBusiness:
          "Front-end, Back-end, Design, Negócios...",
        HowCanYouHelpYouDontHaveToBeAMasterJustWantToHelp:
          "Em que você pode ajudar? Não precisa ser nenhum mestre, basta querer ajudar.",
        male: "Masculino",
        myProfile: "Meu Perfil",
        typeFacebook: "username",
        typeInstagram: "username",
        typeLinkedin: "username",
        typePhone: "(DDD) 9 1234 5678",
        typeTwitter: "username",
        typeSummaryOfYourBioHere: "Digite aqui um resumo de sua biografia",
        typeYourEmail: "Digite seu e-mail",
        typeYourLogin: "Digite seu login",
        typeYourName: "Digite seu nome",
        typeYourBirthday: "DD/MM/AAAA",
        typeYourGender: "Digite seu gênero",
        typeYourSex: "Digite seu sexo",
        typePhoneRamal: "1234",
        typeYourNickname: "Digite como prefere ser chamado",
        typeYourCPF: "Digite o seu CPF",
        typeYourCNPJ: "Digite o seu CNPJ",
        typeYourCEP: "Digite o seu CEP",
        typeYourAddress: "Digite a sua rua",
        typerYourNumber: "Digite o seu nº",
        typeYourComplement: "Se houver",
        typeYourNeighborhood: "Digite o seu bairro",
      },
    },

    settings: {
      confirmPassword: "CONFIRME A SENHA",
      currentPassword: "SENHA ATUAL",
      newPassword: "NOVA SENHA",

      textPage: {
        autoplayOnVideos: "Autoplay nos vídeos do mural",
        exit: "Sair",
        incorrectCurrentPassword: "Senha atual incorreta",
        informPasswords: "Informe as senhas",
        informCurrentPassword: "Informe a senha atual",
        passwordsDoNotMatch: "As senhas não correspondem",
        receiveNotifications: "Receber notificações",
        termsAndConditions: "Termos e condições",
        termsAndPolicy: "Termo de uso e Politica de Privacidade",
        privacyPolicy: "Política de Privacidade",
        deleteMyAccount: "Excluir minha conta",
        typeYourCurrentPassword: "Digite sua senha atual",
        createYourNewPassword: "Crie a sua senha",
        typeYourNewPassword: "Digite uma nova senha segura",
        typeYourConfirmPassword: "Confirme a sua senha",
        updatePassword: "Atualizar senha",
        seeProfile: "Ver meu perfil",
      },
    },

    store: {
      requestedProducts: "Produtos solicitados",
      despatchedProducts: "Produtos enviados",
      limitReached: "Limite atingido",
      backToStore: "Voltar para a loja",
      textPage: {
        allRightContactHRToRedeemYourProduct: storeRedeemMessage(),
        for: "por",
        free: "Grátis",
        notProductsFound: "Nenhum produto foi encontrado na loja",
        notProductsInTheStore: "No momento, não há produtos na loja.",
        keepAccumulatingPoints:
          "Continue acumulando pontos, em breve teremos novidades!",
        outOfStock: "Sem estoque",
        purchaseSuccessful: "Compra realizada com sucesso",
        requested: "Solicitado",
        despatched: "Enviado",
        youHave: "Você tem",
        youWantToBuy: "Você deseja comprar",
        moreDetails: "mais detalhes",
        confirmYourShippingDetails: "Confirme seus dados de envio",
        toRedeemYourPrizeConfirmYourRegisteredDetails:
          "Para efetivar o resgate do seu prêmio, por gentileza confirme seus dados cadastrados:",
        address: "Endereço",
        updateData: "Atualizar dados",
        updateYourInfo: "Atualize suas informações",
        updateInfoButton: "Atualizar informações",
        updatedInformationSuccessfully: "Informações atualizadas com sucesso",
        waitAMomentThisWindowWillCloseAutomatically:
          "Aguarde um instante, essa janela irá fechar automaticamente",
        confirmYouPurchase: "Confirme sua compra",
      },
    },

    apps: {
      textPage: {
        youWillBeRedirectedToThisApplicationWebsite:
          "Você será redirecionado para o site deste aplicativo.",
      },
    },

    publication: {
      textPage: {
        commentSingular: "Comentário",
        comments: "Comentários",
        likes: "Curtidas",
        toComment: "Comentar",
        toLike: "Curtir",
        commentsTitle: "Publicações recentes",
        noCommentsSoFarBeYouTheFirstToContribute:
          "Nenhum comentário até o momento, seja você, o primeiro a contribuir.",
        noLikesSoFarBeYouTheFirstToLike:
          "Nenhuma curtida até o momento, seja você, o primeiro a curtir.",
        tags: "Tags",
        youEillNotBeAbleToChangeTheInformationLater:
          "Você não conseguirá alterar as informações posteriormente",
        commentInputPlaceholder:
          "Você pode enviar seu comentário, pressionando ctrl + enter.",
        commentInputPlaceholderUserGuest:
          "Usuários convidados não estão permitidos a comentar.",
        commentInputResponsivePlaceholder: "Deixe seu comentário",
        typeYourAnswer: "Digite sua resposta",
        hasNoResults: "Nenhum resultado encontrado.",
      },
    },

    scoreHistory: {
      youHave: "você tem",
      meritMoney: "Merit Money",
      scoreRemoval: "Débito de pontos",
      weekInteration: "Interação semanal",
      youAccumulated: "Você acumulou",
      weeklyScore: "Pontuação semanal",
      onTheLastThirtyDays: "nos últimos 30 dias.",
      inTheLastWeekYouReceived: "Na última semana, você recebeu",
      courseConclusion: "Conclusão de curso",
      scorePerPublication: "Pontuação por publicação",
    },

    sacSuvinil: {
      greetings: "Olá pintor, olá pintora!",
      introduction:
        "Este é o seu canal de atendimento para sanar as suas principais dúvidas e facilitar a experiência na plataforma.",
      subtitle: "Pintou Parceria Suvinil",
      chooseCategory: "SELECIONE A CATEGORIA:",
      questions: "Ainda tem dúvidas?",
      contact: "Entre em contato pelo Whatsapp",
      whatsapp: "WHATSAPP",
    },
  },
};
